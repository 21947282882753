import BigNumber from "bignumber.js";

const farms = [
    {
        name: "pancakeLp",
        poolId: 7,
        lpSymbol: "SWFI-BNB",
        earned: "Swfi",
        contractAddress: "0x98683831f230a6a81649d864990ebb3757021e66", //pancake lp address
        contractAdress_2: "0xdb4f6f4da42ccb25b7a462942d81d229ea3ca9ce", // first pair contract address ---- swfi
        firstPairId: "swirge-finance", //coingecko id for first pair
    },
    {
        name: "pancakeLp",
        poolId: 5,
        lpSymbol: "SWG-BNB",
        earned: "Swfi",
        contractAddress: "0x226af4e918fcf3e62e5eeec867a3e78aaa7bb01d", //pancake lp address
        contractAdress_2: "0xe792f64c582698b8572aaf765bdc426ac3aefb6b", // first pair contract address ---- swg
        firstPairId: "swgtoken", //coingecko id for first pair
    },
    /*
    {
        name: "julSwapLp",
        poolId: 3,
        lpSymbol: "SWFI-BNB",
        earned: "Swfi",
        contractAddress: "0x218ACa91691bfFA7D88656832f66c0770A68b4bD" ,// julSwap lp
        contractAdress_2: "0xdb4f6f4da42ccb25b7a462942d81d229ea3ca9ce", // first pair contract address ---- swfi
        firstPairId: "swirge-finance", //first pair id on coingecko
    },
    */
    /* {
        name: "pancakeLp",
        poolId: 6,
        lpSymbol: "LEAD-BNB",
        earned: "Swfi",
        contractAddress: "0x86a9B62311b27BD9eD95d8EAC450797142292fc8", //pancake lp address
        contractAdress_2: "0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66", // first pair contract address ---- lead
        firstPairId: "lead-token", //coingecko id for first pair
    },*/
    {
        name: "pancakeLp",
        poolId: 4,
        lpSymbol: "TFF-BNB",
        earned: "Swfi",
        contractAddress: "0xed8d85A3D5ed80Cd0110c031368D58E2f778a90D", //pancake lp address
        contractAdress_2: "0x2d69c55baEcefC6ec815239DA0a985747B50Db6E", // first pair contract address ---- tff
        firstPairId: "tutti-frutti-finance", //coingecko id for first pair
    },
    // {
    //     name: "pancakeLp",
    //     poolId: 1,
    //     lpSymbol: "SWGB-BNB",
    //     earned: "Swfi",
    //     contractAddress: "0x856f9AD94cA8680B899214Bb1EB3d235a3C33Afe", //pancake lp address
    //     contractAdress_2: "0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb", // first pair contract address ---- swgb
    //     firstPairId: "swirge", //coingecko id for first pair
    // },
]

export const WBNBAdd = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
export const masterChef = "0x9952230759900e0C8655E178F578cBDD0aAa6e38"; //swirge's masterchef contract
export const BSC_BLOCK_TIME = 3;
export const swfiPerBlock = 22;
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365);

export default farms;
