import { useCallback, useContext } from "react";
import { AppContext } from "../../context/app.context";
import ConnectModalContext from "../../context/connectModal.context";
import { useNavigate } from "react-router-dom";
const ExploreButton = () => {
    const navigate = useNavigate();
    const {state : {address: account}} = useContext(AppContext);
    const {open: openModal} = useContext(ConnectModalContext);
    const exploreFunc = useCallback(() => {
        if(account){
            navigate("./farms",{state: {top : true}});
        }else{
            openModal();
        }
    },[account])

    return <button onClick={exploreFunc}> Explore </button>
}

export default ExploreButton;
