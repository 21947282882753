import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Footer, Header } from "./layouts";
import { Farms, Home, Pools } from "./pages";
import { AppProvider } from "./context/app.context";
import { ConnectModal } from "./components";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import { MoralisProvider } from "react-moralis";

function App() {
  return (
    <MoralisProvider
      appId={"1xcH4vB5XI9I4DNo3HzihfQR8XeKYhRxzDeGCGNz"}
      serverUrl={"https://s9qj060n0pzo.usemoralis.com:2053/server/"}>
      <AppProvider>
        <ConnectModal>
          <Suspense fallback={<div>loading...</div>}>
            <div id="app">
              <ToastContainer hideProgressBar="true" theme="colored" />
              {/* header */}
              <Header />
              {/* routes */}
              <div id="main">
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route path="/farms" element={<Farms />} />
                  <Route path="/pools" element={<Pools />} />
                </Routes>
              </div>
              {/* footer */}
              <Footer />
            </div>
          </Suspense>
        </ConnectModal>
      </AppProvider>
    </MoralisProvider>
  );
}

export default App;
