import classNames from 'classnames'
import './input.scss'

const RadioInput = ({ checked, label, onClick, className}) => {
  return (
    <div onClick={onClick} className={classNames('radioinput', className)}>
      {/* The main switch of the radio input  */}
      <div
        className={`radioinput__switch ${
          checked ? 'radioinput__switch--checked' : ''
        }`}
      >
        <div className="radioinput__switch__bulb"></div>
      </div>

      {/* the label of the radio input */}
      <p className="radioinput__label">{label}</p>
    </div>
  )
}

export default RadioInput
