import { NavLink } from "react-router-dom";
import "./navBar.style.scss";

const NavBar = () => {
    return (
        <div id="nav__bar">
            <div className="nav__container">
                <NavLink
                    to="/"
                    className={({ isActive }) => {
                        return `${isActive ? "active" : ""} nav`;
                    }}
                >
                    Home
                </NavLink>
                <NavLink
                    to="/farms"
                    className={({ isActive }) => {
                        return `${isActive ? "active" : ""} nav`;
                    }}
                >
                    Farms
                </NavLink>
                <NavLink
                    to="/pools"
                    className={({ isActive }) => {
                        return `${isActive ? "active" : ""} nav`;
                    }}
                >
                    Pools
                </NavLink>
            </div>
        </div>
    );
};

export default NavBar;
