import axios from "axios";
import { useEffect, useState } from "react";
// import {interval} from "rxjs";

// import { getCoinStat } from "../hooks/poolsConnect";

const baseUrl = "https://server.swirge.com/"

export const useGetStats = () => {
    const [stats, setStats] = useState({});
    const [swgbCirculatingSupply, setSwgbCirculatingSupply] = useState()
    // const [interval$, setInterval$] = useState(null);



    const getStatsCoin = async () => {
        // const swg_stat = await getCoinStat('swirge');
        console.log('test');
        // console.log(swg_stat);

    }
    const getStats = async () => {
        try {
            const { data } = await axios.get(`${baseUrl}/statistics/smartchain/`);
            console.log('this is data', data);
            setStats(data);
        } catch (e) {
            console.warn(e.message);
        }
    };
    const getSWGbCirculatingSupply = async () => {
        try {
            const { data } = await axios.get("http://app.swirge.com/supply/supply/");
            setSwgbCirculatingSupply(data);
        } catch (e) {
            console.warn(e.message);
        }
    }
    useEffect(() => {
        getStats();
        getSWGbCirculatingSupply()
        getStatsCoin();
    }, [])

    // useEffect(() => {
    //     // create observable
    //     getStats()
    //     let $interval = interval(1000 * 30);
    //     setInterval$($interval);
    // },[]);
    // useEffect(() => {
    //     if(interval$){
    //         interval$.subscribe(getStats);
    //     }
    // },[interval$])
    return {

        stats,
        swgbCirculatingSupply,
        getStats

    }
}

export default useGetStats;
