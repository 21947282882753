import classNames from 'classnames'
import './input.scss'

const Input = ({className, name, value, onChange, icon, type, placeholder }) => {
  return (
    <div className={classNames('textinput', className)}>
      { icon }
      <input {...{ name, value, onChange, type, placeholder }} />
    </div>
  )
}

export default Input
