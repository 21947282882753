const CloseICon = () => (
  <svg width={47} height={47} fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx={23.899}
      cy={23.267}
      r={23.062}
      fill="#28B0DD"
      fillOpacity={0.3}
    />
    <path
      d="m32.548 16.361-1.742-1.742-6.907 6.906-6.906-6.906-1.742 1.742 6.906 6.906-6.906 6.907 1.742 1.742 6.907-6.907 6.906 6.907 1.742-1.742-6.907-6.907 6.907-6.906Z"
      fill="#1E82A4"
    />
  </svg>
)

export default CloseICon
