const TelegramCircleIcon = () => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M24 12a12 12 0 1 1-24 0 12 12 0 0 1 24 0Z" fill="#1E96C8" />
    <path
      d="M9.8 18.7c-.39 0-.32-.15-.46-.52L8.2 14.42 15.28 10l.83.22-.69 1.88-5.62 6.6Z"
      fill="#C8DAEA"
    />
    <path
      d="M9.8 18.698a.781.781 0 0 0 .6-.3c.26-.25 3.6-3.5 3.6-3.5l-2.05-.5-1.9 1.2-.25 3v.1Z"
      fill="#A9C9DD"
    />
    <path
      d="m9.998 14.44 4.839 3.57c.55.3.95.15 1.09-.509l1.97-9.28c.2-.81-.31-1.17-.84-.93l-11.559 4.46c-.79.32-.78.76-.14.95l2.97.93 6.87-4.33c.32-.2.62-.09.38.13l-5.58 5.01Z"
      fill="url(#a)"
    />
    <defs>
      <linearGradient
        id="a"
        x1={12.891}
        y1={11.824}
        x2={14.773}
        y2={7.555}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#EFF7FC" />
        <stop offset={1} stopColor="#fff" />
      </linearGradient>
    </defs>
  </svg>
)

export default TelegramCircleIcon
