import { useCallback, useEffect, useState } from "react"
import {toast} from "react-toastify"
function getEth() {
    if ("ethereum" in window) {
    const provider = window.ethereum;
    if (provider.isMathWallet) {
      return provider;
    }
    else {
        // window.open("https://mathwallet.org/", "_blank");
        throw new Error('Math wallet not found')
    }
  }
}

async function hasAccounts() {
    const eth = getEth();
    const accounts = await eth.request({
        method: "eth_accounts"
    });
    return accounts
}

async function requestAccounts() {
    const eth = getEth();
    const accounts = await eth.request({
        method: "eth_requestAccounts"
    });
    return accounts
}

const useMathWallet = () => {
    const [account, setAccount] = useState(undefined);
    const [chainId, setChainId] = useState(0x1);
    const [provider, setProvider] = useState(null);
    //load provider
    const addAccount = useCallback((accounts) => {
        console.log('addAccount',accounts)
        setAccount(accounts[0])
    }, [])    

    const addChanId = useCallback((id) => {
        console.log('chanId', id)
        setChainId(id);
    })
    useEffect(() => {
        window.ethereum?.on?.('accountsChanged', addAccount);
        window.ethereum?.on?.('chainChanged', addChanId);
        return () => {
            window.ethereum?.removeListener?.('accountsChanged', addAccount)
            window.ethereum?.removeListener?.('chainChanged', addChanId);
        }
    },[addAccount,addChanId])

    const mathWalletConnection = async () => {
        try {
            if (!provider) {
                const newProvider = getEth();
                setChainId(newProvider.chainId)
                if (newProvider) {
                    setProvider(newProvider)
                    if (account) {
                        return;
                    }
                    let accounts = await hasAccounts();
                    if (!accounts.length) {
                        await requestAccounts();
                    } else {
                        setAccount(accounts[0])
                    }
                }
            }
        } catch (e) {
           toast.error('Provider connection error') 
        }
    }

    return {
        accountDetails: {
            selectedAccount : account,
            chainId : chainId
        },
        provider,
        connectProvider : mathWalletConnection
    }

}

export default useMathWallet
