import { FarmCard } from "../../components";
import { FarmIcon } from "../../assets/svgs/icons";
import "./homeFarm.style.scss";
import farms from "../../utils/farms";
import { Link } from "react-router-dom";

const HomeFarmCard = () => {
    return (
        <section id="farm_cards">
            <div className="farm-container">
                <div className="farm-cards">
                    {
                     farms.map((farmItem, idx)=>{
                        return <div className="card">
                            <Link to='farms'>
                            <FarmCard 
                                icon={<FarmIcon/>}
                                title="Farm"
                                farmItem={farmItem}
                                arp="80"
                                detail={farmItem.lpSymbol}
                            />
                            </Link>
                        </div>
                     })   
                    }
                    
                </div>
            </div>
        </section>
    )
}
export default HomeFarmCard