const FarmIcon = () => (
  <svg width="25" height="18" viewBox="0 0 25 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1630_181)">
      <path d="M19.3508 13.6C19.3508 12.6886 20.0894 11.95 21.0008 11.95C21.9122 11.95 22.6508 12.6886 22.6508 13.6C22.6508 14.5114 21.9122 15.25 21.0008 15.25C20.0894 15.25 19.3508 14.5114 19.3508 13.6ZM19.4508 13.6C19.4508 14.4558 20.145 15.15 21.0008 15.15C21.8566 15.15 22.5508 14.4558 22.5508 13.6C22.5508 12.7442 21.8566 12.05 21.0008 12.05C20.145 12.05 19.4508 12.7442 19.4508 13.6ZM2.55078 12C2.55078 10.2054 4.00619 8.75 5.80078 8.75C7.59537 8.75 9.05078 10.2054 9.05078 12C9.05078 13.7946 7.59537 15.25 5.80078 15.25C4.00619 15.25 2.55078 13.7946 2.55078 12ZM3.45078 12C3.45078 13.2982 4.50257 14.35 5.80078 14.35C7.09899 14.35 8.15078 13.2982 8.15078 12C8.15078 10.7018 7.09899 9.65 5.80078 9.65C4.50257 9.65 3.45078 10.7018 3.45078 12Z" fill="white" stroke="#8F8798" strokeWidth="1.5"/>
      <path d="M17.0503 7.3568V7.96006L17.6395 8.08937C18.2407 8.2213 18.8433 8.36366 19.4608 8.53477C19.707 8.60324 19.912 8.75144 20.0514 8.94477C17.8427 9.39292 16.2202 11.3729 16.249 13.65H11.929C12.0725 13.1245 12.1503 12.5715 12.1503 12C12.1503 8.45969 9.25529 5.68893 5.89889 5.65566C4.526 5.63356 3.01897 6.09358 1.83147 7.04722L1.83141 7.04727C1.82121 7.05547 1.80965 7.05914 1.79505 7.05751C1.7799 7.05583 1.76908 7.0492 1.76154 7.03978L1.76114 7.03929C1.74381 7.0177 1.747 6.98651 1.769 6.96886C2.33431 6.5152 2.972 6.1625 3.65064 5.92218L4.15028 5.74525V5.2152V0.8C4.15028 0.772614 4.1729 0.75 4.20028 0.75H8.51948C9.29205 0.75 9.96054 1.28532 10.1293 2.03771L10.1293 2.03795L11.1797 6.71475L11.3029 7.26321L11.8639 7.29889C13.1975 7.38371 14.6016 7.5392 16.0741 7.79051L16.9503 7.94004V7.0512V4.8C16.9503 4.65582 16.9865 4.51983 17.0503 4.40066V4.8V7.3568ZM17.8003 4.05H17.4009C17.5201 3.98621 17.6561 3.95 17.8003 3.95H18.6003C18.6277 3.95 18.6503 3.97261 18.6503 4C18.6503 4.02739 18.6277 4.05 18.6003 4.05H17.8003ZM4.25028 4.848V5.69096L5.08752 5.59291C6.73975 5.39941 8.422 5.87321 9.75961 6.9202L11.4139 8.21502L10.9537 6.1653L10.0321 2.0605L10.0318 2.05941C9.87153 1.35041 9.24766 0.85 8.51948 0.85H5.00028H4.25028V1.6V4.848Z" fill="white" stroke="#8F8798" strokeWidth="1.5"/>
    </g>
    <defs>
      <filter id="filter0_d_1630_181" x="0" y="0" width="24.4023" height="18" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="0.5"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.560784 0 0 0 0 0.529412 0 0 0 0 0.596078 0 0 0 0.64 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1630_181"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1630_181" result="shape"/>
      </filter>
    </defs>
  </svg>
)

export default FarmIcon