const pools = [
    // {
    //     id: "lead-swg", //earning/staked
    //     logo: "swgb.svg", //logo of staked
    //     token: "SWG", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
    //     earned: "0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66", //lead
    //     poolsRewardAddress: "0x075D2FDD3B2089918f0DF0E6265a35Ec9125369B",
    //     projectLink: "https://leadwallet.io/",
    //     stakingTokenId: "swgtoken",
    //     earnedTokenId: "lead-token",
    //     active: false,
    // },
    // {
    //     id: "tff-swfi", //earning/staked
    //     logo: "swfi.svg", //logo of staked
    //     token: "SWFI", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xdb4f6f4da42ccb25b7a462942d81d229ea3ca9ce", //swfi
    //     earned: "0x2d69c55baEcefC6ec815239DA0a985747B50Db6E", //tff
    //     poolsRewardAddress: "0x81816b140F2A562b21c3279c59D84249BF39a5a4",
    //     projectLink: "https://tuttifrutti.finance/",
    //     stakingTokenId: "swirge-finance",
    //     earnedTokenId: "tutti-frutti-finance", //coingecko
    //     active: false,

    // },
    {
        id: "swg-swglp", //earning/staked
        logo: "swgb.svg", //logo of staked
        token: "SWG", //token of staked
        tokenDecimal: 18,
        tokenAddress: "0x226Af4e918fCf3E62E5eeEC867A3e78aAa7BB01d", //swglp
        earned: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
        poolsRewardAddress: "0x39Ff0CcF02F573531AfAc3b2b6F01c8B76687321",
        projectLink: "https://pancakeswap.finance/swap?outputCurrency=0xe792f64C582698b8572AAF765bDC426AC3aEfb6B/",
        stakingTokenId: "swgtoken",
        earnedTokenId: "swgtoken", //coingecko
        active: true,

    },
    // {
    //     id: "lead-swfi", //earning/staked
    //     logo: "swfi.svg", //logo of staked
    //     token: "SWFI", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xdb4f6f4da42ccb25b7a462942d81d229ea3ca9ce", //swfi
    //     earned: "0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66", //lead
    //     poolsRewardAddress: "0x2585CFee3A61b98d88731193a6b9fFB4fDF80964",
    //     projectLink: "https://leadwallet.io/",
    //     stakingTokenId: "swirge-finance",
    //     earnedTokenId: "lead-token",
    //     active: true,
    // },
    // {
    //     id: "pepe2-swg", //earning/staked
    //     logo: "swgb.svg", //logo of staked
    //     token: "SWG", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
    //     earned: "0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00", //pepe
    //     poolsRewardAddress: "0x0Bc8f1Eb4DC5581e5baF0D687F7a4a1aeA916f52",
    //     projectLink: "https://swirge.com/",
    //     stakingTokenId: "swgtoken",
    //     earnedTokenId: "pepe",
    //     active: false,
    // },
    // {
    //     id: "floki2-swg", //earning/staked
    //     logo: "swgb.svg", //logo of staked
    //     token: "SWG", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
    //     earned: "0xfb5B838b6cfEEdC2873aB27866079AC55363D37E", //floki
    //     poolsRewardAddress: "0xca31828e38F58BD0Eba1cAc28466e13F70D30cf2",
    //     projectLink: "https://swirge.com/",
    //     stakingTokenId: "swgtoken",
    //     earnedTokenId: "floki",
    //     active: false,
    // },
    // {
    //     id: "pepe3-swg", //earning/staked
    //     logo: "swgb.svg", //logo of staked
    //     token: "SWG", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
    //     earned: "0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00", //pepe
    //     poolsRewardAddress: "0x09BBeFF76fe0E2642e477BfaE6264fe25a7e9468",
    //     projectLink: "https://swirge.com/",
    //     stakingTokenId: "swgtoken",
    //     earnedTokenId: "pepe",
    //     active: false,
    // },
    // {
    //     id: "elon-swg", //earning/staked
    //     logo: "swgb.svg", //logo of staked
    //     token: "SWG", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
    //     earned: "0x7bd6FaBD64813c48545C9c0e312A0099d9be2540", //elon
    //     poolsRewardAddress: "0x90A565Ce994a994636d23ff57e17fD967C75C93e",
    //     projectLink: "https://swirge.com/",
    //     stakingTokenId: "swgtoken",
    //     earnedTokenId: "dogelon-mars",
    //     active: false,
    // },
    // {
    //     id: "doge2-swg", //earning/staked
    //     logo: "swgb.svg", //logo of staked
    //     token: "SWG", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
    //     earned: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43", //doge
    //     poolsRewardAddress: "0xEA3b4Ccd9fcf24C9E77b321Ba988325b94F8f912",
    //     projectLink: "https://swirge.com/",
    //     stakingTokenId: "swgtoken",
    //     earnedTokenId: "dogecoin",
    //     active: false,
    // },
    // {
    //     id: "doge-swg", //earning/staked
    //     logo: "swgb.svg", //logo of staked
    //     token: "SWG", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
    //     earned: "0xbA2aE424d960c26247Dd6c32edC70B295c744C43", //doge
    //     poolsRewardAddress: "0xDbF73F9859d5EEf49Cc375a7F32Dd342d13498f0",
    //     projectLink: "https://swirge.com/",
    //     stakingTokenId: "swgtoken",
    //     earnedTokenId: "dogecoin",
    //     active: true,
    // },
    // {
    //     id: "pepe-swg", //earning/staked
    //     logo: "swgb.svg", //logo of staked
    //     token: "SWG", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
    //     earned: "0x25d887Ce7a35172C62FeBFD67a1856F20FaEbB00", //pepe
    //     poolsRewardAddress: "0x7481F120ACFbCE24dBDF34fe8183aeB360bfd591",
    //     projectLink: "https://swirge.com/",
    //     stakingTokenId: "swgtoken",
    //     earnedTokenId: "dogecoin",
    //     active: true,
    // },
    // {
    //     id: "floki-swg", //earning/staked
    //     logo: "swgb.svg", //logo of staked
    //     token: "SWG", //token of staked
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe792f64C582698b8572AAF765bDC426AC3aEfb6B", //swg
    //     earned: "0xfb5B838b6cfEEdC2873aB27866079AC55363D37E", //floki
    //     poolsRewardAddress: "0x27D2330C1414F3eC7d22a8AF0Ae662428c400C01",
    //     projectLink: "https://swirge.com/",
    //     stakingTokenId: "swgtoken",
    //     earnedTokenId: "dogecoin",
    //     active: false,
    // },
    // {
    //     id: "swgb-safep",
    //     logo: "swgb.svg",
    //     token: "SWGB",
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb", //swgb
    //     earned: "0xA8c514D991F59baB02d32b68f04204cB89261c88",
    //     poolsRewardAddress: "0x5342A93851AA202b24A8Fa5f155db9299c2036Ca",
    //     projectLink: "/",
    //     stakingTokenId: "swirge",
    //     earnedTokenId: "",
    //     active: false,
    // },
    // {
    //     id: "swgb-lead",
    //     logo: "swgb.svg",
    //     token: "SWGB",
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb", //swgb
    //     earned: "0x2ed9e96EDd11A1fF5163599A66fb6f1C77FA9C66",
    //     poolsRewardAddress: "0xd4f150106a87B3b3e8e743f5bF0D412c0b3B9B9b",
    //     projectLink: "https://leadwallet.io/",
    //     stakingTokenId: "swirge",
    //     earnedTokenId: "lead-token",
    //     active: false,
    // },
    // {
    //     id: "swgb-tff",
    //     logo: "swgb.svg",
    //     token: "SWGB",
    //     tokenDecimal: 18,
    //     tokenAddress: "0xe40255c5d7fa7ceec5120408c78c787cecb4cfdb", //swgb
    //     earned: "0x2d69c55baEcefC6ec815239DA0a985747B50Db6E", //tff
    //     poolsRewardAddress: "0x8aC6B3e1Db1BFcea95FDDC2ED4f8FAB6942e92d2",
    //     projectLink: "https://tuttifrutti.finance/",
    //     stakingTokenId: "swirge", //coingecko
    //     earnedTokenId: "tutti-frutti-finance", //coingecko 
    //     active: false,
    // }
]
export default pools