import './table.scss'

const TableLayout = ({ children }) => {
    return (
        <div className="table__container__holder">
            <div className='table'>
                { children }
            </div>
        </div>
    )
}

export default TableLayout;
