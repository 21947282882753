import "./footer.style.scss";
import Icons from "../../assets/svgs/icons";
import logo from "../../assets/img/logo.png";
import { ThemeToggle } from "../../components";
import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <>
            <section id="footer">
                <div className="container">
                    <div className="group menu">
                        <div className="title">Menu</div>
                        <div className="contents">
                            <Link to="/" className="info">
                                <div className="icon"><Icons.HomeIcon /></div>
                                <div>Home</div>
                            </Link>
                            <Link
                                to="https://about.swirge.com/team" className="info"
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                <div className="icon">
                                    <Icons.TeamIcon />
                                </div>
                                <div className="text">
                                    Team
                                </div>
                            </Link>
                            <Link
                                to="https://about.swirge.com/whitepaper" className="info"
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                <div className="icon">
                                    <Icons.WhitepaperIcon />
                                </div>
                                <div className="text">
                                    Whitepaper
                                </div>
                            </Link>
                            <Link
                                to="https://github.com/Swirge/" className="info"
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                <div className="icon">
                                    <Icons.GithubIcon />
                                </div>
                                <div className="text">
                                    Github
                                </div>
                            </Link>
                            <Link
                                to="https://about.swirge.com/" className="info"
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                <div className="icon">
                                    <Icons.FileIcon />
                                </div>
                                <div className="text">
                                    About Us
                                </div>
                            </Link>
                            <Link
                                to="https://about.swirge.com/contact" className="info"
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                <div className="icon">
                                    <Icons.PhoneIcon />
                                </div>
                                <div className="text">
                                    Contact
                                </div>
                            </Link>
                            <Link
                                to="https://about.swirge.com/ecosystem" className="info"
                                rel="noreferrer"
                                target={"_blank"}
                            >
                                <div className="icon">
                                    <Icons.EcoIcon />
                                </div>
                                <div className="text">Ecosystem</div>
                            </Link>
                            <Link
                                to="https://swirge.com" className="info app"
                                rel="noreferrer"
                                target={"_blank"}>
                                <div className="icon">
                                    <img src={logo} alt="swirge logo" />
                                </div>
                                <div className="text">Swirge app</div>
                            </Link>
                        </div>
                    </div>
                    <div className="group products">
                        <div className="title">Products</div>
                        <div className="contents">
                            <div className="product">
                                Swirge Social Media
                            </div>
                            {/* <div className="product">
                                Swirge Marketplace
                            </div> */}
                            <div className="product">
                                Swirge finance
                            </div>
                        </div>

                    </div>
                    <div className="group contact">
                        <div className="title">Contact</div>
                        <div className="contents">
                            <div className="top">
                                <div className="card email">
                                    <div className="icon">
                                        <Icons.EmailIcon />
                                    </div>
                                    <div>
                                        {/* <Link to="myRoute" params={myParams} target="_blank"></Link> */}
                                        <Link to="https://about.swirge.com/contact" target="_blank" rel="noopener noreferrer"> support@swirge.com  </Link>
                                    </div>

                                </div>
                                {/* <div className="card phone">
                                    <div className="icon">
                                        <Icons.PhoneIcon/>
                                    </div>
                                    <div className="numbers">
                                        <div>
                                            +234 812 345 6789
                                        </div>
                                        <div>
                                            +234 812 345 6789
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="handles">
                                <Link
                                    to="https://www.linkedin.com/company/swirge/"
                                    rel="noreferrer"
                                    target={"_blank"}
                                    className="handle"
                                >
                                    <Icons.LinkedInIcon />
                                </Link>
                                <Link
                                    to="https://instagram.com/swirgeapp?utm_medium=copy_link"
                                    rel="noreferrer"
                                    target={"_blank"}
                                    className="handle"
                                >
                                    <Icons.InstaIcon />
                                </Link>
                                <Link
                                    to="https://t.me/swirgepay"
                                    rel="noreferrer"
                                    target={"_blank"}
                                    className="handle"
                                >
                                    <Icons.TelIcon />
                                </Link>
                                <Link
                                    to="https://twitter.com/swirgenetwork"
                                    rel="noreferrer"
                                    target={"_blank"}
                                    className="handle"
                                >
                                    <Icons.TwitterIcon />
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="copyright">
                <div className="container">
                    <ThemeToggle />
                    <div className="content">
                        <div className="cp">
                            © Swirge 2022 - 2023. All rights reserved.
                        </div>
                        <div className="links terms">
                            <Link to="/">Terms and Conditions</Link>
                        </div>
                        <div className="links privacy">
                            <Link to="/">Privacy</Link>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}

export default Footer