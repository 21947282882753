import { useCallback, useContext } from "react";
import { AppContext } from "../../context/app.context";
import ConnectModalContext from "../../context/connectModal.context";
import "./connectWalletButton.styles.scss";

const ConnectWalletButton = () => {
  const { open: openModal } = useContext(ConnectModalContext);
  const {state: { address } } = useContext(AppContext);

  const getAccount = useCallback(() => {
    return `${address.slice(
    0,
    6
  )}....${address.slice(
    address.length - 4
          )}`; 
  }, [address])
  
  const connect = useCallback(() => {
    if (!address) {
      openModal(); 
    }
  } ,[address, openModal])

  return (
      
      <button className="connect_btn" onClick={() => {
          if (address) return;
            connect()
          }}>
            {address
          ? getAccount()
          : "Connect Wallet"}
      </button>
  )
}
export default ConnectWalletButton
