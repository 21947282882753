import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import './input.scss'

const DualChoiceSwitch = ({ values, className }) => {
  return (
    <div className={classNames('dualChoiceSwitch', className)}>
      <button
        onClick={values[0].onClick}
        className={values[0].active && 'active'}
      >
        {values[0].value}
      </button>
      <button
        onClick={values[1].onClick}
        className={values[1].active && 'active'}
      >
        {values[1].value}
      </button>
    </div>
  )
}

export default DualChoiceSwitch
