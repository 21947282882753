import { Link } from "react-router-dom";
import "./coinCard.style.scss";

const CoinCard = ({ name, icon, price }) => {
    return (
        <div className="coin_card">
            <div className="logo_name">
                <div className="logo">
                    {icon}
                </div>
                <p className="name">{name}</p>
            </div>
            <div className="price">
                {price}
            </div>
            <Link target={'_blank'} rel="noreferrer" to={'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe792f64c582698b8572aaf765bdc426ac3aefb6b'}>
            <button className="buy_button">
                Buy
            </button>
            </Link>

        </div>
    )
}

export default CoinCard