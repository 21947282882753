import HomeIcon from "./homeIcon";
import EmailIcon from "./EmailIcon";
import EcoIcon from "./EcoIcon";
import FileIcon from "./FileIcon";
import GithubIcon from "./GithubIcon";
import InstaIcon from "./InstaIcon";
import PhoneIcon from "./PhoneIcon";
import TeamIcon from "./TeamIcon";
import TelIcon from "./TelIcon";
import TwitterIcon from "./Twitter";
import WhitepaperIcon from "./WhitepaperIcon";
import LinkedInIcon from "./LinkedInIcon";
import SunIcon from "./SunIcon";
import TwitterCircle from "./TwitterCircle";
import TelegramCircleIcon from "./TelCircleIcon";
import FarmIcon from "./FarmIcon";
import PoolIcon from "./PoolIcon";
import LockIcon from "./LockIcon";
import CloseIcon from "./closeIcon";

export {
    HomeIcon,
    EcoIcon,
    FileIcon,
    GithubIcon,
    InstaIcon,
    PhoneIcon,
    TeamIcon,
    TelIcon,
    TwitterIcon,
    WhitepaperIcon,
    LinkedInIcon,
    EmailIcon,
    SunIcon,
    TwitterCircle,
    TelegramCircleIcon,
    PoolIcon,
    FarmIcon,
    LockIcon,
    CloseIcon,
}
const Icons = {
    HomeIcon,
    EcoIcon,
    FileIcon,
    GithubIcon,
    InstaIcon,
    PhoneIcon,
    TeamIcon,
    TelIcon,
    TwitterIcon,
    WhitepaperIcon,
    LinkedInIcon,
    EmailIcon,
    SunIcon,
    TwitterCircle,
    TelegramCircleIcon,
    FarmIcon,
    PoolIcon,
    LockIcon,
    CloseIcon
}
export default Icons