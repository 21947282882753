const TelegramIcon = () => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m8.25 12.645 8.429 7.418a.75.75 0 0 0 1.226-.396L21.432 4.28a.75.75 0 0 0-1.006-.866L3.124 10.211a.75.75 0 0 0 .127 1.434l4.998 1ZM8.25 12.645l12.76-9.216"
      stroke="var(--icon-path, #EFF0F6)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m12.46 16.35-2.93 2.93a.75.75 0 0 1-1.28-.531v-6.104"
      stroke="var(--icon-path, #EFF0F6)"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default TelegramIcon