import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { LockIcon } from "../../assets/svgs/icons";
import { AppContext } from "../../context/app.context";
import "./farmCard.style.scss";

const FarmCard = ({icon,title,arp,detail , farmItem}) => {
    const {
        state: { farmsData, address }
    } = useContext(AppContext);
    const [farmData, setFarmsData] = useState({});
    useEffect(() => {
        if(!address){
            return
        }
        if (farmsData) {
            let data = farmsData.find((item) => {
                return item?.lpSymbol === detail;
            });
            if (data) {
                setFarmsData(data);
            }else{
                toast.error(`Unable to get farm data for ${farmItem.lpSymbol}`)
            }
        }
    }, [farmsData]);
    return (
        <div className="farm-card" tabIndex={0} title={`${detail}-card`}>
            <div className="card-icon">{icon}</div>
            <div className="card-title">
                {title}
            </div>
            <div className="center">
                <div className="lock-icon">
                    <LockIcon/>
                </div>
                <img src={`/assets/${detail}.svg`} alt="logo" width="64px" height={'64px'}/>
            </div>
            <div className="detail">
                {detail}
            </div>
            <div className="arp">
                ARP: <span>{(Number((farmData?.apr) / 100) || 0).toFixed(4) || "0"}%</span> 
            </div>
        </div>
    )
}

export default FarmCard