const TwitterCircle = () => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12Z"
      fill="#55ACEE"
    />
    <path
      d="M19.348 8.623a5.723 5.723 0 0 1-1.647.452 2.876 2.876 0 0 0 1.26-1.587 5.741 5.741 0 0 1-1.82.696 2.869 2.869 0 0 0-4.888 2.617A8.142 8.142 0 0 1 6.34 7.802a2.868 2.868 0 0 0 .888 3.83 2.848 2.848 0 0 1-1.3-.36v.037c0 1.39.99 2.549 2.302 2.812a2.858 2.858 0 0 1-1.296.05 2.871 2.871 0 0 0 2.68 1.992 5.755 5.755 0 0 1-4.247 1.187 8.113 8.113 0 0 0 4.397 1.29c5.276 0 8.16-4.371 8.16-8.162a8.15 8.15 0 0 0-.008-.371 5.816 5.816 0 0 0 1.432-1.485Z"
      fill="#F1F2F2"
    />
  </svg>
)

export default TwitterCircle