const PhoneIcon = () => (
  <svg width={19} height={19} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.814 3.428c.002 0-.042.045-.097.1-.212.205-.65.633-.654 1.528-.007 1.252.816 3.577 4.78 7.54 3.947 3.945 6.268 4.778 7.523 4.778h.018c.896-.004 1.322-.443 1.529-.654.063-.065.114-.112.15-.142.871-.877 1.317-1.53 1.313-1.948-.006-.427-.537-.932-1.271-1.63a41.744 41.744 0 0 1-.755-.731c-.694-.693-1.038-.574-1.794-.308-1.046.366-2.48.865-4.71-1.365-2.233-2.232-1.734-3.665-1.368-4.71.264-.756.385-1.101-.31-1.796-.272-.271-.516-.528-.74-.765-.695-.73-1.195-1.257-1.62-1.263h-.007c-.419 0-1.07.448-1.991 1.37l.004-.004Zm11.553 15.259c-2.18 0-5.023-1.736-8.45-5.162C2.475 10.085.736 7.234.75 5.05c.008-1.444.765-2.183 1.05-2.46.014-.019.065-.069.082-.086C3.137 1.247 3.983.74 4.825.749c.977.013 1.673.745 2.553 1.672.218.229.454.478.718.74 1.277 1.278.913 2.32.621 3.157-.318.912-.594 1.7 1.056 3.35 1.652 1.65 2.44 1.375 3.349 1.054.837-.293 1.877-.658 3.156.62.259.258.505.492.731.708.931.884 1.666 1.584 1.679 2.564.01.835-.497 1.687-1.75 2.94l-.555-.376.469.459c-.277.284-1.016 1.042-2.46 1.05h-.025Z"
      fill="var(--icon-path, #EFF0F6)"
    />
  </svg>
)

export default PhoneIcon
