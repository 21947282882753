import { createContext } from "react";
import useWalletConnect from "../hooks/connectWallet";
import useGetStats from "../hooks/getStats";
import useFarms from "../hooks/useFarms";
import usePools from "../hooks/usePools";
import useSWGStat from "../hooks/useSWGStat";

export const AppContext = createContext({ actions: {}, state: {} });

export const AppProvider = ({ children }) => {
  const WalletState = useWalletConnect();
  const SwgStat = useSWGStat();
  const farmsData = useFarms({
    web3: WalletState.state.web3,
    address: WalletState.state.address,
  });
  const poolsState = usePools({
    web3: WalletState.state.web3,
    address: WalletState.state.address,
  });
  const { stats, getStats } = useGetStats();

  const state = {
    ...WalletState.state,
    SwgStat,
    ...farmsData.state,
    ...poolsState.state,
    stats,
  };
  const actions = {
    ...WalletState.actions,
    ...farmsData.actions,
    pools: poolsState.actions,
    getStats,
  };
  return (
    <AppContext.Provider value={{ state, actions }}>
      {children}
    </AppContext.Provider>
  );
};
