import WalletConnectProvider from "@walletconnect/web3-provider";
import { useCallback, useEffect, useState } from "react"
import Web3 from "web3";
import {toast} from "react-toastify"

const INFURA_ID = "6462c0080ea541d29715be204dc56443";

const useWalletConnectHook = () => {
    const [account, setAccount] = useState(undefined);
    const [provider, setProvider] = useState(null)
    const [chainId, setChainId] = useState("0x1");

    //load provider

    const addAccount = useCallback((accounts) => {
        // console.log('addAccount',accounts)
        setAccount(accounts[0])
    }, [])    

    const addChainId = useCallback((id) => {
        // console.log('chainId', id)
        setChainId(id);
    });

     useEffect(()=>{
        if(provider){
            fetchAccountData()

            // Subscribe to accounts change
            provider.on("accountsChanged", addAccount);

            // Subscribe to chainId change
            provider.on("chainChanged", addChainId);

            provider.on("disconnect", () => {
                setAccount(undefined);
                setProvider(null);
            });
        }
        return () => {
            provider?.disconnect();
        } 

     }, [provider]);
    
    const fetchAccountData = useCallback(async () => {
        const web3 = new Web3(provider)
        const chainId = await web3.eth.getChainId();
        setChainId(chainId)
        const accounts = await web3.eth.getAccounts();
        console.log(accounts);
        setAccount(accounts[0])
    }, [provider]);

    const walletConnection = async () => {
        try {
            if (provider) return;
            const newProvider = new WalletConnectProvider({
                infuraId: INFURA_ID
            });
            await newProvider.enable();
            setProvider(newProvider)
            return newProvider;
        } catch (e) {
           toast.error('Provider connection error') 
            console.warn(e.message)
        }
    }

    return {
         accountDetails: {
            selectedAccount : account,
            chainId : chainId
        },
        provider,
        connectProvider : walletConnection,
    }

}

export default useWalletConnectHook
