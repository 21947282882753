
const GithubIcon = () => (
  <svg width={19} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13 19.25v-3.386a2.948 2.948 0 0 0-.822-2.284c2.747-.306 5.634-1.348 5.634-6.125A4.76 4.76 0 0 0 16.5 4.174a4.436 4.436 0 0 0-.079-3.299S15.39.569 13 2.17a11.708 11.708 0 0 0-6.125 0C4.486.569 3.454.875 3.454.875a4.436 4.436 0 0 0-.079 3.299A4.76 4.76 0 0 0 2.062 7.48c0 4.743 2.888 5.784 5.636 6.125a2.948 2.948 0 0 0-.823 2.258v3.386m0-2.625C2.5 17.938 2.5 14.437.75 14l6.125 2.625Z"
      stroke="var(--icon-path, #EFF0F6)"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default GithubIcon
