const singleStaking = [
    {   
        logo: "swfi.svg",
        poolId: 0,
        projectLink: "https://swirgepay.com",
        id: "SWFI",
        earnedToken: "SWFI",
        contractAddress: "0xdb4f6f4da42ccb25b7a462942d81d229ea3ca9ce", //token address
        active: true,
    },
]
export default singleStaking 
