import "./stats.style.scss";
import WalletImage from "../../assets/img/WalletImage.png";
const Stats = () => {
    return (
        <div className="coin_stat" id="coin_stat">
            <img src={WalletImage} alt="wallet-logo" />
        </div>
    )
}
export default Stats
