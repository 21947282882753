const LockIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_1630_216)">
      <circle cx="16" cy="15" r="12" fill="white"/>
      <circle cx="16" cy="15" r="11.4" stroke="#D9DBE9" strokeWidth="1.2"/>
    </g>
    <path opacity="0.4" d="M18.7351 10.9862C18.3349 9.8303 17.2368 9.00002 15.9447 9.00002C14.3147 8.99356 12.9876 10.309 12.9805 11.939V11.952V13.3446" stroke="#28B0DD" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M18.4158 20.9997H13.4596C12.1014 20.9997 11 19.8983 11 18.5394V15.7581C11 14.3992 12.1014 13.2979 13.4596 13.2979H18.4158C19.774 13.2979 20.8754 14.3992 20.8754 15.7581V18.5394C20.8754 19.8983 19.774 20.9997 18.4158 20.9997Z" stroke="#28B0DD" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.9375 16.4287V17.87" stroke="#28B0DD" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round"/>
    <defs>
      <filter id="filter0_d_1630_216" x="0" y="0" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="1"/>
        <feGaussianBlur stdDeviation="2"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.654618 0 0 0 0 0.873092 0 0 0 0 0.945382 0 0 0 0.36 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1630_216"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1630_216" result="shape"/>
      </filter>
    </defs>
  </svg>
)

export default LockIcon