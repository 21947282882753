// entry point for all page files
import { lazy } from "react";; 
const Home = lazy(() => import("./home"));
const Farms = lazy(() => import("./farms"));
const Pools = lazy(() => import('./pools'));

export {
    Home,
    Farms,
    Pools
}