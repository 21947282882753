import Web3 from 'web3';
import { useCallback, useEffect, useState } from 'react';
import useMetaMask from './useMetaMask';
import useWalletConnectHook from './useWalletConnect';
import useMathWallet from './useMathWallet';


const useWalletConnect = () => {
    const [provider, setProvider] = useState(null);
    const metaMaskC = useMetaMask()
    const walletConnect = useWalletConnectHook();
    const MathWallet = useMathWallet();
    const [account, setAccountDetails] = useState(undefined);
    const [web3, setWeb3] = useState(null);

    useEffect(
        () => {
            setAccountDetails(metaMaskC.accountDetails.selectedAccount || walletConnect.accountDetails.selectedAccount || MathWallet.accountDetails.selectedAccount)
       }
   ,[metaMaskC.accountDetails, walletConnect.accountDetails, MathWallet.accountDetails])
     
    useEffect(() => {
        if(provider){
            let web3 = new Web3(provider);
            setWeb3(web3);
        }else{
            setWeb3(null);
        }
    },[provider])

    const getChainId = useCallback(async () => {
        if(web3){
            let id = await web3.eth.getChainId();
            return id
        }
        return 1
    },[web3])


    const connectToProvider = async (wallet) => {
        try {
            // await metaMaskC.connectProvider();
            if (wallet === "math") {
                await MathWallet.connectProvider();
                setProvider(MathWallet.provider);
                return;
            }
            if (wallet === "wc") {
                let wc_provider = await walletConnect.connectProvider();
                setProvider(wc_provider);
                return;
            }
            await metaMaskC.connectProvider();
            setProvider(metaMaskC.provider);
        }catch(err){
            console.log(err)
        }
    }
    return {
        state: {
            address: account, 
            provider,
            web3
        },
        actions: {
            connectToProvider, 
            getChainId
        }
    }
}
export default useWalletConnect
