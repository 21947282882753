import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../context/app.context";
import ConnectModalContext from "../../context/connectModal.context";
import "./connectModal.style.scss";
import BCWLogo from "../../assets/img/bcw.png";
import Wc from "../../assets/img/walletConnect.png";
import trustW from "../../assets/img/TWT.png";
import MetaLogo from "../../assets/img/metamask.png";
import MathLogo from "../../assets/img/mathWallet.png";
import SafeLogo from "../../assets/img/safeP.png";
import TokenP from "../../assets/img/tokenp.png";

    
const ConnectModal = ({ children }) => {
    const [show, setShow] = useState(false);
    const { actions: { connectToProvider }, state : { address } } = useContext(AppContext);
    const connectProvider = async (p) => {
        try{
            await connectToProvider(p);
            // setShow(v => !v);
        }catch(e){
            console.warn(e.message);
        }
    }
    useEffect(() => {
        if(address){
           setShow(false);
        }
    },[address])

    return (
        <ConnectModalContext.Provider
            value={{ open: () => setShow(true), close: () => setShow(false) }}
        >
            {children}
            <div id={`connect-modal`} className={`${show ? "show" : ""}`}>
                <div className="modal">
                    <header>
                        <div className="header-text">
                            Connect to a wallet
                        </div>
                        <div className="close" onClick={() => setShow(v => !v)}>
                            X
                        </div>
                    </header> 
                    <div className="modal-body">
                        <div className="button" onClick={() => connectProvider()}>
                            <div className="icon meta">
                                <img src={MetaLogo} alt="meta-mask" />
                            </div>
                            <div className="text">Metamask</div>
                        </div>
                        <div className="button" onClick={() => connectProvider("wc")}>
                            <div className="icon meta">
                                <img src={Wc} alt="wallet-connect" />
                            </div>
                            <div className="text">WalletConnect</div>
                        </div>
                        <div className="button" onClick={() => connectProvider("wc")}>
                            <div className="icon ">
                                <img src={trustW} alt="trust-wallet" />
                            </div>
                            <div className="text">TrustWallet</div>
                        </div>
                        <div className="button" onClick={() => connectProvider()}>
                            <div className="icon binance">
                                <img src={BCWLogo} alt="bcl" />
                            </div>
                            <div className="text">Binance Chain Wallet</div>
                        </div>
                        <div className="button" onClick={() => connectProvider('math')}>
                           <div className="icon math">
                                <img src={MathLogo} alt="math-connect" />
                            </div>
                            <div className="text">Math Wallet</div>
                        </div>
                        <div className="button" onClick={() => connectProvider()}>
                            <div className="icon meta">
                                <img src={SafeLogo} alt="safe-connect" />
                            </div>
                            <div className="text">SafePal Wallet</div>
                        </div>
                        <div className="button" onClick={() => connectProvider()}>
                            <div className="icon">
                                <img src={TokenP} alt="tokenpocket" />
                            </div>
                            <div className="text">Tokenpocket</div>
                        </div> 
                    </div>
                </div>
            </div>
        </ConnectModalContext.Provider>
    )
}

export default ConnectModal;
