const More = (props) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
        {...props}
    
  >
    <path d="M0 0h18v2H0V0Zm0 7h12v2H0V7Zm0 7h18v2H0v-2Z" fill="#000" />
  </svg>
)

export default More
