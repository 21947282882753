const EcoIcon = () => (
  <svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.5 1.75 1.75 6.125 10.5 10.5l8.75-4.375L10.5 1.75ZM1.75 14.875l8.75 4.375 8.75-4.375M1.75 10.5l8.75 4.375 8.75-4.375"
      stroke="var(--icon-path, #EFF0F6)"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default EcoIcon
