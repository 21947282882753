import "./homeP.style.scss"

const HomeParagraph = ({ title, text }) => {
    return (
        <>
            <p className="hero-title">
                {title}
            </p>
            {
                text && 
                <p className="hero-detail">
                    {text}
                </p>
            }
        </>
    )
}

export default HomeParagraph