const SunIcon = () => (
  <svg width={18} height={18} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 12.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5ZM9 .75v1.5M9 15.75v1.5M3.164 3.165 4.23 4.23M13.77 13.77l1.065 1.066M.75 9h1.5M15.75 9h1.5M3.164 14.835 4.23 13.77M13.77 4.23l1.065-1.065"
      stroke="var(--primary)"
      strokeWidth={1.2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SunIcon
