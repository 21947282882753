import { HomeParagraph, InfoCard } from "../../components";
import Logo from "../../assets/img/logo.png"
import { useContext, useEffect } from "react";
import { AppContext } from "../../context/app.context";
import "./homeBuy.style.scss";
import useGetStats from "../../hooks/getStats";
import { getPrice } from "../../utils/statFunc";
import { Link } from "react-router-dom";

const formatter = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });
const HomeBuySection = () => {
    const { state: { SwgStat, stats } } = useContext(AppContext);
    const { state, actions } = useGetStats()

    return (
        <section id="buy">
            <div className="buy-container">
                <HomeParagraph
                    title={"$SWG Token"}
                    text={"$SWG is the primary token on the Swirge network used for staking, payments and on- chain governance across the ecosystem"}
                />
                <div className="buy-btn-div">
                    <Link target={'_blank'} to={'https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xe792f64c582698b8572aaf765bdc426ac3aefb6b'}>
                        <button className="buy-btn">
                            Buy SWG
                        </button>
                    </Link>
                </div>

                <div className="buy-coin-info">
                    <div className="c">
                        <InfoCard
                            label="token"
                            value={
                                <>
                                    <div className="image">
                                        <img src={Logo} alt="token-logo" />
                                    </div>
                                    {SwgStat?.symbol?.toUpperCase()}
                                </>
                            }
                        />
                    </div>
                    <div className="c">
                        <InfoCard
                            label="Current price"
                            value={`${formatter.format(SwgStat?.current_price)}`}
                        />
                    </div>
                    <div className="c">
                        <InfoCard
                            label="Total supply"
                            value={`SWG ${SwgStat?.total_supply || 0}`}
                        />
                    </div>
                    <div className="c">
                        <InfoCard
                            label="Circulating supply"
                            value={`SWG 3200000`}
                        />
                    </div>
                    <div className="c">
                        <InfoCard
                            label="Total burned"
                            value={`SWG ${(stats?.swgbBurnedAmount)?.toFixed(2) || 0}`}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default HomeBuySection
