import "./homeHero.style.scss";
import { CoinCard, ConnectWalletButton, HomeParagraph, Stats, ExploreButton} from "../../components";
import { TelegramCircleIcon, TwitterCircle } from "../../assets/svgs/icons";
import swirgeIcon from "../../assets/img/SwirgeICon.png"
import CodeIcon from "../../assets/img/code.png";
import swirgeLogo from "../../assets/img/logo.png"
import NavBar from "../../components/NavBar";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/app.context";
import { getPrice } from "../../utils/statFunc";
import { Link } from "react-router-dom";

const HomeHero = () => {
    const { state : { SwgStat, stats } } = useContext(AppContext);
    const [swfiPrice, setSwfiPrice] = useState(getPrice('swirge-finance'))
    const [storedSwfiPrice, setStoredSwfiPrice]  = useState(localStorage.getItem('swifi-price'))
    console.log(storedSwfiPrice);
    return (
        <section id="home-hero">
            <NavBar/>
            <div className="hero-container">
                <Stats />
                <HomeParagraph
                    title={"Intro to Swirge Finance"}
                    text={"Simplicity is at the core of our innovation. Make highly-secured financial transactions with ease. Swirge finance is fully compatible with Metamask and Wallet Connect"}
                />
                <div className="hero-handles">
                    <Link
                         to="https://twitter.com/swirgenetwork"
                        rel="noreferrer"
                        target={"_blank"}
                    >
                        <TwitterCircle />
                    </Link>
                    
                    <Link to="https://swirge.com/"
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        <img src={swirgeIcon} alt="swirge logo" />
                    </Link>
                    <Link
                         to="https://t.me/swirgepay"
                        rel="noreferrer"
                        target={"_blank"}
                    >
                        <TelegramCircleIcon/>
                    </Link>
                </div>
                <div className="hero-coins">
                    <CoinCard name={"SWG"}
                        price={`$ ${(SwgStat?.current_price)?.toFixed(2)}`}
                        icon={<img src={swirgeLogo} alt="code_icon"/>}
                    />
                    <CoinCard
                        name={"SWFI"}
                        price={`$ ${storedSwfiPrice && Number(storedSwfiPrice).toFixed(2)}`}
                        icon={<img src={'/assets/SWFI-BNB.svg'} alt="code_icon"/>}
                    />
                    <CoinCard
                        name={"CODE"}
                        price={"soon!"}
                        icon={<img src={CodeIcon} alt="code_icon"/>}
                    />
                </div>
                <div className="hero-wallet">
                    <div className="text">
                            Perform transactions without ‘third-party Influence’, stake and earn SWG tokens and other stable coins as rewards on BNB Smart Chain on Swirge.
                    </div>
                    <div>
                        <ConnectWalletButton/>
                    </div>
                </div>
                <HomeParagraph
                    title={ "Stake LP tokens to earn rewards"}
                    text={"Stake-as-you-go for maximum flexibility and long-term sustainability"}
                />
                <div className="hero-explore">
                    <ExploreButton/>
                </div>
            </div>
        </section>
    )
}
export default HomeHero
