import { useState, useEffect, useCallback } from "react";
import {interval} from "rxjs";
import { getCoinStat } from "../utils/statFunc";

const useSWGStat = () => {
    const [stat, setStat] = useState({})
    const [loading,setLoading] = useState(false);
    const [interval$, setInterval$] = useState(null);

    const getStats = useCallback(async () => {
        try{
            if(loading){
                return 
            }
            let stat  = await getCoinStat('swgtoken');
            if(stat){      
                let data = {
                    symbol : stat.symbol, 
                    current_price: stat.market_data?.current_price.usd, 
                    current_supply:"3200000",
                    total_supply: "8000000",
                }
                setStat(data);
            }
        }catch(e){
            console.warn(e.message)
        }
        setLoading(v => !v)
    },[])
    useEffect(() => {
        // create observable
        getStats()
        let $interval = interval(1000 * 30);
        setInterval$($interval);
    },[]);
    useEffect(() => {
        if(interval$){
            interval$.subscribe(getStats);
        }
    },[interval$])
    console.log('stat', stat);
    return stat
}
export default useSWGStat
