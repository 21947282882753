const TeamIcon = () => (
  <svg width={20} height={16} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.4 9.612h.1l.22.001c1.69.011 5.55.182 5.55 2.798 0 2.6-3.726 2.77-5.523 2.78h-.666c-1.69-.01-5.55-.181-5.55-2.795 0-2.602 3.86-2.772 5.55-2.783h.32Zm0 1.313c-2.078 0-4.557.255-4.557 1.471 0 1.19 2.33 1.467 4.368 1.483h.19c2.079 0 4.557-.254 4.557-1.468 0-1.228-2.478-1.486-4.557-1.486Zm6.96-1.668c2.12.318 2.566 1.313 2.566 2.093 0 .476-.188 1.345-1.439 1.822a.657.657 0 0 1-.467-1.227c.593-.226.593-.475.593-.595 0-.383-.487-.65-1.448-.794a.658.658 0 0 1-.552-.748.66.66 0 0 1 .747-.55Zm-13.172.551a.658.658 0 0 1-.552.748c-.961.143-1.449.41-1.449.794 0 .12 0 .369.595.595a.656.656 0 1 1-.468 1.227C1.062 12.694.875 11.825.875 11.35c0-.779.445-1.775 2.566-2.093a.66.66 0 0 1 .747.551ZM10.4.5a4.002 4.002 0 0 1 3.998 3.998A4.002 4.002 0 0 1 10.4 8.496h-.024a3.955 3.955 0 0 1-2.816-1.178 3.954 3.954 0 0 1-1.157-2.823A4.002 4.002 0 0 1 10.4.5Zm0 1.313a2.688 2.688 0 0 0-2.685 2.685 2.654 2.654 0 0 0 .776 1.895 2.657 2.657 0 0 0 1.887.79l.022.657v-.657a2.688 2.688 0 0 0 2.685-2.685 2.689 2.689 0 0 0-2.685-2.686Zm5.415-.456a3.16 3.16 0 0 1 2.656 3.13 3.187 3.187 0 0 1-2.73 3.135.657.657 0 0 1-.181-1.3 1.866 1.866 0 0 0 1.598-1.838 1.85 1.85 0 0 0-1.556-1.832.657.657 0 0 1 .213-1.295Zm-10.076.54a.657.657 0 0 1-.541.755 1.851 1.851 0 0 0-1.557 1.834A1.865 1.865 0 0 0 5.24 6.322a.656.656 0 1 1-.181 1.3 3.186 3.186 0 0 1-2.73-3.134 3.16 3.16 0 0 1 2.656-3.13.653.653 0 0 1 .755.54Z"
      fill="var(--icon-path, #EFF0F6)"
    />
  </svg>
)

export default TeamIcon