import { useEffect, useState, useCallback } from "react";
import pools from "../utils/pools";
import { toast } from "react-toastify";
import abi, { rewardAbi } from "../utils/abi";
import { masterChef, BLOCKS_PER_YEAR } from "../utils/farms";
import BigNumber from "bignumber.js";
import { getPrice } from "../utils/statFunc";
import singleStaking from "../utils/singleStaking";
import { ethers } from "ethers";
const tokenPerBlock = 22;
const usePools = ({ address, web3 }) => {
    const [singleStakeData, setSingleStakeData] = useState();
    const [poolsData, setPoolsData] = useState([]);

    const getChainId = useCallback(async () => {
        if (web3) {
            let id = await web3.eth.getChainId();
            return id;
        }
        return 1;
    }, [web3]);

    const getPoolsBalance = useCallback(
        async ({ address }) => {
            if (!address || !web3) {
                return;
            }

            const chainId = await getChainId();
            if (chainId !== 0x38 && chainId !== 56) {
                // notify error
                toast.warning("Provider is not on binance smartchain");
                return;
            }
            try {
                let promises = pools.map((pool) => {
                    return new Promise(async (resolve) => {
                        try {
                            const {
                                id,
                                poolsRewardAddress,
                                tokenAddress,
                                tokenDecimal,
                                earnedTokenId,
                                stakingTokenId,
                            } = pool;
                            const data = {};
                            const [tokenName, earnedTokenName] = id.split("-");
                            const tokenContract = new web3.eth.Contract(
                                abi[tokenName],
                                tokenAddress
                            );
                            // const earnedTokenContract = new web3.eth.Contract(abi[earnedTokenName], earned);
                            const rewardPoolContract = new web3.eth.Contract(
                                rewardAbi,
                                poolsRewardAddress
                            );
                            data.id = id;
                            data.allowance =
                                Number(
                                    await tokenContract.methods
                                        .allowance(address, poolsRewardAddress)
                                        .call()
                                ) /
                                10 ** tokenDecimal;
                            data.stakedAmount =
                                Number(
                                    await rewardPoolContract.methods
                                        .balanceOf(address)
                                        .call()
                                ) /
                                10 ** tokenDecimal;
                            data.walletBalance =
                                Number(
                                    await tokenContract.methods
                                        .balanceOf(address)
                                        .call()
                                ) /
                                10 ** tokenDecimal;
                            data.earned =
                                Number(
                                    await rewardPoolContract.methods
                                        .earned(address)
                                        .call()
                                ) /
                                10 ** tokenDecimal;
                            data.totalStaked =
                                Number(
                                    await rewardPoolContract.methods
                                        .totalSupply()
                                        .call()
                                ) /
                                10 ** tokenDecimal;
                            const price_1 = await getPrice(stakingTokenId);
                            const price_2 =
                                (await getPrice(earnedTokenId)) || 0;
                            const totalRewardPricePerYear = new BigNumber(
                                price_2
                            )
                                .times(tokenPerBlock)
                                .times(BLOCKS_PER_YEAR);
                            const totalStakingTokenPool = new BigNumber(
                                price_1
                            ).times(data.totalStaked);
                            data.apr = new BigNumber(totalRewardPricePerYear)
                                .div(totalStakingTokenPool)
                                .toFixed(1);
                            // console.log(price_1, price_2, data.totalStaked);
                            data.totalValue = data.totalStaked * price_1;
                            resolve(data);
                        } catch (e) {
                            toast.error(e.message);
                            resolve(null);
                        }
                    });
                });
                Promise.all(promises).then((v) => setPoolsData(v));
            } catch (e) {
                console.error(e);
            }
        },
        [web3, getChainId]
    );

    const approvePool = useCallback(
        async ({ id }) => {
            const token = id.split("-")[0];
            const pool = pools.filter((pool) => pool.id === id && pool)[0];
            const contract = new web3.eth.Contract(
                abi[token],
                pool.tokenAddress
            );
            try {
                const approve = await contract.methods
                    .approve(
                        pool.poolsRewardAddress,
                        ethers.constants.MaxUint256
                    )
                    .send({ from: address });
                if (approve) {
                    getPoolsBalance({ address });
                    toast.success("pool approved");
                }
            } catch (err) {
                toast.error("there was an error during approval");
            }
        },
        [web3, address]
    );
    const stakePool = useCallback(
        async ({ amount, id }) => {
            const pool = pools.filter((pool) => pool.id === id && pool)[0];
            const contract = new web3.eth.Contract(
                rewardAbi,
                pool.poolsRewardAddress
            );
            try {
                const data = await contract.methods
                    .stake(web3.utils.toWei(`${amount}`, "ether"))
                    .send({ from: address });
                if (data) {
                    getPoolsBalance({ address });
                    toast.success("pool staked successfully");
                }
            } catch (err) {
                toast.error("there was an error during staking");
            }
        },
        [web3, address]
    );

    const unstake = useCallback(
        async ({ amount, id }) => {
            const pool = pools.filter((pool) => pool.id === id && pool)[0];
            const contract = new web3.eth.Contract(
                rewardAbi,
                pool.poolsRewardAddress
            );
            try {
                const data = await contract.methods
                    .withdraw(web3.utils.toWei(`${amount}`, "ether"))
                    .send({ from: address });
                if (data) {
                    getPoolsBalance({ address });
                }
            } catch (err) {
                toast.error("Error while unstaking");
            }
        },
        [web3, address]
    );

    const harvest = useCallback(
        async ({ id }) => {
            const pool = pools.filter((pool) => pool.id === id && pool)[0];
            const contract = new web3.eth.Contract(
                rewardAbi,
                pool.poolsRewardAddress
            );
            try {
                const data = await contract.methods
                    .getReward()
                    .send({ from: address });
                if (data) {
                    getPoolsBalance({ address });
                    toast.success("pools havested successfully");
                }
            } catch (err) {
                toast.error("error while Harvesting... ");
            }
        },
        [web3, address]
    );

    const exit = useCallback(
        async ({ id }) => {
            const pool = pools.filter((pool) => pool.id === id && pool)[0];
            const contract = new web3.eth.Contract(
                rewardAbi,
                pool.poolsRewardAddress
            );
            try {
                const data = await contract.methods
                    .exit()
                    .send({ from: address });
                if (data) {
                    getPoolsBalance({ address });
                    toast.success("pools Exited successfully");
                }
            } catch (err) {
                toast.error("error while Exiting... ");
            }
        },
        [web3, address]
    );

    const emergencyWithdraw = useCallback(
        async ({ amount }) => {
            const contract = new web3.eth.Contract(abi.masterChef, masterChef);
            try {
                const data = await contract.methods
                    .emergencyWithdraw(web3.utils.toWei(`${amount}`, "ether"))
                    .send({ from: address });
                if (data) {
                    getSingleStakingBalance();
                    toast.success("successfully exited staking");
                }
            } catch (err) {
                console.log(err);
                toast.error("error occured while existing stake");
            }
        },
        [web3, address]
    );

    const getSingleStakingBalance = useCallback(async () => {
        if (!address || !web3) {
            return;
        }
        const chainId = await getChainId();
        if (chainId !== 0x38 && chainId !== 56) {
            // notify error
            toast.warning("Provider is not on binance smartchain");
            return;
        }
        try {
            const promises = singleStaking.map(async (token) => {
                return new Promise(async (resolve) => {
                    try {
                        const newFarmsData = {};
                        const contract = new web3.eth.Contract(
                            abi[token.id.toLowerCase()],
                            token.contractAddress
                        );
                        const masterChefContract = new web3.eth.Contract(
                            abi.masterChef,
                            masterChef
                        );
                        newFarmsData.id = token.id;
                        newFarmsData.balance =
                            Number(
                                await contract.methods.balanceOf(address).call()
                            ) /
                            10 ** 18;
                        newFarmsData.allowance = Number(
                            await contract.methods
                                .allowance(address, masterChef)
                                .call()
                        );
                        newFarmsData.staked =
                            Number(
                                (
                                    await masterChefContract.methods
                                        .userInfo(token.poolId, address)
                                        .call()
                                ).amount
                            ) /
                            10 ** 18;
                        newFarmsData.earned =
                            Number(
                                await masterChefContract.methods
                                    .pendingSwfi(token.poolId, address)
                                    .call()
                            ) /
                            10 ** 18;
                        newFarmsData.x =
                            Number(
                                (
                                    await masterChefContract.methods
                                        .poolInfo(token.poolId)
                                        .call()
                                ).allocPoint
                            ) / 100;
                        const totalStaked =
                            (await contract.methods
                                .balanceOf(masterChef)
                                .call()) /
                            10 ** 18;

                        const tokenPrice = await getPrice("swirge-finance");

                        newFarmsData.totalLiquidity = new BigNumber(totalStaked)
                            .times(tokenPrice)
                            .toFixed(1);
                        // console.log(newFarmsData.totalLiquidity)

                        const totalRewardPricePerYear = new BigNumber(
                            tokenPrice
                        )
                            .times(tokenPerBlock)
                            .times(BLOCKS_PER_YEAR);
                        const totalStakingTokenPool = new BigNumber(
                            tokenPrice
                        ).times(totalStaked);
                        newFarmsData.apr = new BigNumber(
                            totalRewardPricePerYear
                        )
                            .div(totalStakingTokenPool)
                            .toFixed(1);

                        console.log(newFarmsData) 
                        resolve(newFarmsData);
                        
                    } catch (e) {
                        console.error(e)
                        toast.error(e.message)
                    }
                });
            });  
            Promise.all(promises).then(v => setSingleStakeData(v));                
        } catch (e) {
            console.log(e)
        }
    }, [web3, address]);

    const enterStaking = useCallback(
        async ({ amount }) => {
            const contract = new web3.eth.Contract(abi.masterChef, masterChef);
            try {
                const data = await contract.methods
                    .enterStaking(web3.utils.toWei(`${amount}`, "ether"))
                    .send({ from: address });
                if (data) {
                    getSingleStakingBalance();
                    toast.success("successfully entered staking");
                }
            } catch (err) {
                toast.error("error while entering staking");
            }
        },
        [web3, address]
    );

    const approveSingle = useCallback(
        async ({ id }) => {
            const stakes = singleStaking.filter(
                (stakes) => stakes.id === id && stakes
            )[0];
            const contract = new web3.eth.Contract(
                abi[stakes.id.toLowerCase()],
                stakes.contractAddress
            );
            try {
                const data = await contract.methods
                    .approve(masterChef, ethers.constants.MaxUint256)
                    .send({ from: address });
                if (data) {
                    getSingleStakingBalance();
                    toast.success("successfully appoved single stake");
                }
            } catch (err) {
                toast.error("Error while Approving");
            }
        },
        [web3, address]
    );

    const leaveStaking = useCallback(
        async ({ amount }) => {
            const contract = new web3.eth.Contract(abi.masterChef, masterChef);
            try {
                const data = await contract.methods
                    .leaveStaking(web3.utils.toWei(`${amount}`, "ether"))
                    .send({ from: address });
                if (data) {
                    getSingleStakingBalance();
                    toast.success("successfully left staking");
                }
            } catch (err) {
                console.log(err);
                toast.error("error occured while leaving staking");
            }
        },
        [web3, address]
    );

    useEffect(() => {
        if (web3 && address) {
            getPoolsBalance({ address });
            getSingleStakingBalance();
        }
    }, [web3, address]);
    return {
        state: {
            poolsData,
            singleStakeData,
        },
        actions: {
            approvePool,
            stakePool,
            unstake,
            harvest,
            exit,
            emergencyWithdraw,
            enterStaking,
            approveSingle,
            leaveStaking,
        },
    };
};

export default usePools;
