import { useCallback, useEffect, useState } from "react"
import detectEthereumProvider from '@metamask/detect-provider';
import {toast} from "react-toastify";

function getEth() {
    const eth = window.ethereum;
    if (!eth) {
        console.warn("get metamask extension")
    }
    return eth
}

async function hasAccounts() {
    const eth = getEth();
    const accounts = await eth.request({
        method: "eth_accounts"
    });
    return accounts
}

async function requestAccounts() {
    const eth = getEth();
    const accounts = await eth.request({
        method: "eth_requestAccounts"
    });
    return accounts
}

const useMetaMask = () => {
    const [account, setAccount] = useState(undefined);
    const [chainId, setChainId] = useState(0x1);
    const [provider, setProvider] = useState(window.ethereum)
    //load provider
    const addAccount = useCallback((accounts) => {
        // console.log('addAccount',accounts)
        setAccount(accounts[0])
    }, [])    

    const addChainId = useCallback((id) => {
        // console.log('chainId', id)
        setChainId(id);
    })
    useEffect(() => {
        window.ethereum?.on?.('accountsChanged', addAccount);
        window.ethereum?.on?.('chainChanged', addChainId);
        return () => {
            window.ethereum?.removeListener?.('accountsChanged', addAccount)
            window.ethereum?.removeListener?.('chainChanged', addChainId);
        }
    },[addAccount,addChainId])

    const metaMaskConnection = async () => {
        try {
            const provider = await detectEthereumProvider();
            setProvider(provider);
            if (provider) {
                if (provider !== window.ethereum) {
                    console.error('Do you have multiple wallets installed?');
                }
                if (account) {
                    return;
                }
                let accounts = await hasAccounts();
                if (!accounts.length) {
                    await requestAccounts();
                } else {
                    setAccount(accounts[0])
                }
                
            } else {
                throw new Error("get metamask extension");
            }
        } catch (e) {
           toast.error('Provider connection error') 
        }
    }

    return {
        accountDetails: {
            selectedAccount : account,
            chainId : chainId
        },
        provider,
        connectProvider : metaMaskConnection
    }

}

export default useMetaMask
