import BigNumber from "bignumber.js";
import { toast } from "react-toastify";
import { useCallback, useEffect, useState } from "react";
import farms, {
  BLOCKS_PER_YEAR,
  masterChef,
  swfiPerBlock,
  WBNBAdd,
} from "../utils/farms";
import { getPrice } from "../utils/statFunc";
import abi from "../utils/abi";
import { ethers } from "ethers";

export const getFarmsApr = async ({
  swfiPrice,
  totalLiquidity,
  poolWeight,
}) => {
  const yearlyCakeRewardAllocation = new BigNumber(swfiPerBlock)
    .times(BLOCKS_PER_YEAR)
    .times(poolWeight);
  const apr = new BigNumber(yearlyCakeRewardAllocation)
    .times(swfiPrice)
    .div(totalLiquidity)
    .times(100);
  return apr;
};

const EigthFold = 10 ** 18;

const useFarms = ({ web3, address }) => {
  const [farmsData, setFarmsData] = useState([]);

  const getChainId = useCallback(async () => {
    if (web3) {
      let id = await web3.eth.getChainId();
      return id;
    }
    return 1;
  }, [web3]);

  // get farms fuction
  const getFarmsbalance = useCallback(
    async ({ address }) => {
      if (!address || !web3) {
        return;
      }
      const chainId = await getChainId();
      if (chainId !== 0x38 && chainId !== 56) {
        // notify error
        toast.warning("Provider is not on binance smartchain");
        return;
      }
      try {
        let promises = farms.map((farm) => {
          //   console.log(farm);
          return new Promise(async (resolve) => {
            try {
              const firstTokenPair = farm.lpSymbol.split("-")[0];
              const contract = new web3.eth.Contract(
                abi[farm.name],
                farm.contractAddress
              );
              const masterChefContract = new web3.eth.Contract(
                abi.masterChef,
                masterChef
              );
              // pending swifi
              const pendingSwfi =
                Number(
                  await masterChefContract.methods
                    .pendingSwfi(farm.poolId, address)
                    .call()
                ) / EigthFold;
              // balance
              const balance =
                Number(await contract.methods.balanceOf(address).call()) /
                EigthFold;
              // allowance
              const allowance = Number(
                await contract.methods.allowance(address, masterChef).call()
              );
              const stakedLp =
                (
                  await masterChefContract.methods
                    .userInfo(farm.poolId, address)
                    .call()
                ).amount / EigthFold;
              const allocPoint = Number(
                (await masterChefContract.methods.poolInfo(farm.poolId).call())
                  .allocPoint
              );
              const totalAllocPointMasterChef = await masterChefContract.methods
                .totalAllocPoint()
                .call();
              const poolWeight =
                Number(allocPoint) / Number(totalAllocPointMasterChef);
              const swfiPiceUsd = await getPrice("swirge-finance");

              //get wbnb and first pair balance
              const WBNBContract = new web3.eth.Contract(abi.WBNB, WBNBAdd);
              const WBNBBalance = new BigNumber(
                await WBNBContract.methods
                  .balanceOf(farm.contractAddress)
                  .call()
              )
                .div(EigthFold)
                .times(await getPrice("wbnb"))
                .toFixed(1);
              const providedTokenContract = new web3.eth.Contract(
                abi[firstTokenPair.toLowerCase()],
                farm.contractAdress_2
              );
              const firstPairBalance = new BigNumber(
                await providedTokenContract.methods
                  .balanceOf(farm.contractAddress)
                  .call()
              )
                .div(EigthFold)
                .times(await getPrice(farm.firstPairId))
                .toFixed(1);
              const totalLiquidity = new BigNumber(WBNBBalance)
                .plus(firstPairBalance)
                .toFixed(1);
              const apr = (
                await getFarmsApr({
                  swfiPrice: swfiPiceUsd,
                  totalLiquidity: totalLiquidity,
                  poolWeight,
                })
              ).toFixed(1);
              resolve({
                name: farm.name,
                earned: farm.earned,
                lpSymbol: farm.lpSymbol,
                poolId: farm.poolId,
                apr,
                totalLiquidity,
                allowance,
                balance,
                pendingSwfi,
                allocPoint,
                stakedLp,
                x: allocPoint / 100,
              });
            } catch (e) {
              toast.error(e.message);
              resolve(null);
            }
          });
        });
        Promise.all(promises).then((v) => setFarmsData(v));
      } catch (e) {
        console.error(e);
      }
    },
    [web3]
  );

  const approveFarm = useCallback(
    async ({ poolId }) => {
      const farm = farms.filter((farm) => farm.poolId === poolId && farm)[0];
      const contract = new web3.eth.Contract(
        abi[farm.name],
        farm.contractAddress
      );
      try {
        const approve = await contract.methods
          .approve(masterChef, ethers.constants.MaxUint256)
          .send({ from: address });
        toast.success(`${farm.lpSymbol} farm approve`);
        if (approve) {
          getFarmsbalance({ address });
        }
      } catch (err) {
        toast.error("there was an error during approval", err);
      }
    },
    [web3, address]
  );

  const stakeLp = useCallback(
    async ({ poolId, amount }) => {
      const amountInWei = web3.utils.toWei(`${amount}`, "ether");
      const contract = new web3.eth.Contract(abi.masterChef, masterChef);
      try {
        const tx = await contract.methods
          .deposit(poolId, amountInWei)
          .send({ from: address });
        if (tx) {
          getFarmsbalance({ address });
        }
        return tx;
      } catch (err) {
        toast.error(err.message);
      }
      return;
    },
    [web3, address]
  );

  const getFarmsDetails = useCallback(async () => {
    if (address) {
      getFarmsbalance({ address });
    }
  }, [web3, address]);

  const unstake = useCallback(
    async ({ poolId, amount }) => {
      try {
        const contract = new web3.eth.Contract(abi.masterChef, masterChef);
        const data = await contract.methods
          .withdraw(poolId, web3.utils.toWei(`${amount}`, "ether"))
          .send({ from: address });
        if (data) {
          getFarmsbalance({ address });
        }
      } catch (err) {
        toast.error(
          "Error while unstaking.. please ensure you have the right amount"
        );
      }
    },
    [web3, address]
  );

  const harvest = useCallback(
    async ({ poolId }) => {
      const contract = new web3.eth.Contract(abi.masterChef, masterChef);
      try {
        const data = await contract.methods
          .deposit(poolId, 0)
          .send({ from: address });
        if (data) {
          getFarmsbalance({ address });
        }
      } catch (err) {
        toast.error("Error while harvesting...");
      }
    },
    [web3, address]
  );

  useEffect(() => {
    if (web3 && address) {
      getFarmsbalance({ address });
    }
  }, [web3, address]);

  return {
    state: {
      farmsData,
    },
    actions: {
      approveFarm,
      stakeLp,
      unstake,
      getFarmsDetails,
      harvest,
    },
  };
};

export default useFarms;
