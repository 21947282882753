import Logo from "../../assets/img/logo.png"
import More from "../../assets/svgs/more";
import CloseHeader from "../../assets/svgs/closeHeader";
import { Link } from "react-router-dom";
import { useState } from "react";
import { ConnectWalletButton, ThemeToggle } from "../../components";
import "./header.style.scss";

const Header = () => {
    const [open,setOpen] = useState(false)
    return (
        <section id="header">
            <div className="container">
                <div className="more_icon" onClick={() => setOpen(v => !v)}>
                    <CloseHeader className={`close ${open && "show"}`}/>
                    <More className={`more ${!open && "show" }`}/> 
                </div>
                <Link className="logo" to="/">
                    <div className="logo-img">
                        <img src={Logo} alt="swirge logo"/>
                    </div>
                    <div className="logo-text">
                        Finance
                    </div>
                </Link>
                <div className="navs">
                   <Link  to="https://about.swirge.com/contact"
                            rel="noreferrer"
                            target={"_blank"}
                        >Contact</Link>
                        <Link
                             to="https://github.com/Swirge/"rel="noreferrer"
                            target={"_blank"}
                        >Github</Link>
                        <Link to="https://docs.swirge.com/"
                                rel="noreferrer"
                                target={"_blank"}>Docs</Link>
                        <Link to="https://swirgenetwork.medium.com"
                            rel="noreferrer"
                            target={"_blank"}
                        >Meduim</Link>
                </div>

                <div className="connect">
                    <ConnectWalletButton />
                </div>
                <div className={`dropdown ${open && "open"}`}>
                    <div className="close-header"><div className="close" onClick={() => setOpen(v => !v)}>X</div></div>
                    <div className="navs">
                        <Link to="https://about.swirge.com/contact"
                            rel="noreferrer"
                            target={"_blank"}
                        >Contact</Link>
                        <Link
                             to="https://github.com/Swirge/"rel="noreferrer"
                            target={"_blank"}
                        >Github</Link>
                        <Link to="https://docs.swirge.com/"
                                rel="noreferrer"
                                target={"_blank"}>Docs</Link>
                        <Link to="swirgenetwork.medium.com"
                            rel="noreferrer"
                            target={"_blank"}
                        >Meduim</Link>
                    </div>
                    <ThemeToggle/>
                </div>
            </div>
        </section>
    )
}

export default Header
