const WhitepaperIcon = () => (
  <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.664 2.666h1.333a1.333 1.333 0 0 1 1.334 1.333v9.334a1.333 1.333 0 0 1-1.334 1.333h-8a1.334 1.334 0 0 1-1.333-1.333V3.999a1.333 1.333 0 0 1 1.333-1.333h1.334"
      stroke="var(--icon-path, #EFF0F6)"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.003 1.334h-4a.667.667 0 0 0-.667.667v1.333c0 .368.298.667.667.667h4a.667.667 0 0 0 .666-.667V2.001a.667.667 0 0 0-.666-.667Z"
      stroke="var(--icon-path, #EFF0F6)"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default WhitepaperIcon
