import axios from "axios";
// import Moralis from "moralis - 0.000353044793136999";
import { interval } from "rxjs";
// import { useMoralisWeb3Api } from "react-moralis";
const apiKey =
  "bAWL7QzOExIwVQef57N9o5viUGzTORWDxZsCh3iwF7nCvpGF3AqWHKFu3uii2nce";
const baseUrl = "https://huddlz.xyz/api/v2/";

const options = {
  address: "0xdb4f6f4da42ccb25b7a462942d81d229ea3ca9ce",
  chain: "bsc",
  exchange: "PancakeSwapv2",
};
const intervalOb$ = interval(1000 * 30);

const getPriceV2 = async (options = {}) => {
  const { chain, exchange, address } = options;
  const { data } = await axios({
    baseURL: baseUrl,
    method: "get",
    params: {
      chain,
      exchange,
    },
    url: `/erc20/${address}/price`,
    headers: {
      ["X-API-Key"]: apiKey,
    },
  });
  return data;
};

const IntervalPriceFetch = async () => {
  //   const Web3Api = useMoralisWeb3Api();

  const data = await getPriceV2(options);
  if (data) {
    const price = "data.usdPrice";
    localStorage.setItem("swifi-price", price);
    return price;
  }
};

const getSwirgeFiancePrice = async () => {
  const token = localStorage.getItem("swifi-price");
  if (token) {
    return token;
  } else {
    return IntervalPriceFetch();
  }
};

intervalOb$.subscribe(IntervalPriceFetch);

export const getPrice = async (tokenId) => {
  if (tokenId === "swirge-finance") {
    const swfiPrice = "0.000353044793136999";
    return swfiPrice;
  }
  const baseUrl = "https://api.coingecko.com/api/v3/";
  const price =
    "0.001"
  return price;
};

export const getCoinStat = async (tokenId) => {
  try {
    const baseUrl = "https://api.coingecko.com/api/v3/";
    const response = await axios.get(`${baseUrl}/coins/${tokenId}`);
    return response.data;
  } catch (e) {
    console.warn(e.messsage);
  }
};
