import "./infoCard.style.scss";

const InfoCard = ({label, value }) => {
    return (
        <div className="info-card">
            <div className="label">{label}</div>
            <div className="info">{value}</div>
        </div>
    )
}

export default InfoCard