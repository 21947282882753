const FileIcon = () => (
  <svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.25 1.75h-7A1.75 1.75 0 0 0 3.5 3.5v14a1.75 1.75 0 0 0 1.75 1.75h10.5a1.75 1.75 0 0 0 1.75-1.75V7l-5.25-5.25Z"
      stroke="var(--icon-path, #EFF0F6)"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.25 1.75V7h5.25M14 11.375H7M14 14.875H7M8.75 7.875H7"
      stroke="var(--icon-path, #EFF0F6)"
      strokeWidth={1.3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default FileIcon