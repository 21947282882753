const HomeIcon = () => (
  <svg width={19} height={20} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.002 13.38c1.054 0 1.911.851 1.911 1.898v2.691c0 .225.18.405.412.41h1.667c1.315 0 2.383-1.055 2.383-2.351V8.394a1.467 1.467 0 0 0-.584-1.145l-5.768-4.601a2.114 2.114 0 0 0-2.635.002l-5.73 4.597a1.466 1.466 0 0 0-.595 1.162v7.619c0 1.296 1.068 2.352 2.382 2.352H6.13c.237 0 .43-.188.43-.42 0-.05.005-.101.016-.15v-2.532c0-1.04.852-1.891 1.898-1.898h2.53Zm3.99 6.312H13.31c-.964-.023-1.708-.78-1.708-1.723v-2.691a.593.593 0 0 0-.599-.586H8.477a.593.593 0 0 0-.59.586v2.682a.635.635 0 0 1-.027.189 1.74 1.74 0 0 1-1.731 1.543H4.445c-2.038 0-3.695-1.644-3.695-3.664V8.403a2.76 2.76 0 0 1 1.102-2.19l5.718-4.59a3.423 3.423 0 0 1 4.27-.001l5.759 4.593a2.763 2.763 0 0 1 1.088 2.17v7.643c0 2.02-1.657 3.664-3.695 3.664Z"
      fill="var(--icon-path, #EFF0F6)"
    />
  </svg>
)

export default HomeIcon
