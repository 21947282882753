const EmailIcon = () => (
  <svg width={21} height={21} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.577 1.75c2.913 0 5.11 2.377 5.11 5.53v6.01c0 1.613-.57 3.07-1.607 4.105-.93.927-2.137 1.418-3.49 1.418H5.97c-1.35 0-2.557-.49-3.488-1.418C1.445 16.361.875 14.903.875 13.29V7.28c0-3.153 2.197-5.53 5.11-5.53h8.592Zm0 1.313H5.986c-2.2 0-3.798 1.773-3.798 4.217v6.01c0 1.262.433 2.39 1.221 3.175.679.678 1.565 1.035 2.563 1.035h8.605c.001-.002.008 0 .014 0 .998 0 1.883-.357 2.563-1.035.788-.785 1.221-1.913 1.221-3.175V7.28c0-2.444-1.598-4.218-3.798-4.218Zm1.379 4.05a.657.657 0 0 1-.096.923l-3.888 3.16a2.662 2.662 0 0 1-3.324.005L4.723 8.039a.657.657 0 0 1 .824-1.022l3.92 3.16a1.35 1.35 0 0 0 1.683-.003l3.882-3.156a.655.655 0 0 1 .924.096Z"
      fill="var(--icon-path, #EFF0F6)"
    />
  </svg>
)

export default EmailIcon